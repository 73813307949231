import "bootstrap";

$(document).ready(function(){
    $(".checkbox_submit").change(function(e){
        var src = $(this);
        var id = e.target.id.split("-")[1];
        var value = e.target.id.split("-")[2]
        $.ajax({
          type: "PUT",
          url: "/tickets/"+id+"?value="+value,
          data: {show_msg: $("#mycheckbox").is(":checked")}
      });
    });
});

$(document).ready(function(){
  $('.check_me').click(function () {
    if ($(this).is(':checked')) {
        $('#deleteCheckedItems').removeAttr('disabled'); //enable input
    } else {
        $('#deleteCheckedItems').attr('disabled', true); //disable input
    }
  });
});

$(document).ready(function () {
  $('#printButton').click(function () {
    window.print().submit();
    return false
    //window.location.href = '/tickets/new'
  })
});
